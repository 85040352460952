// extracted by mini-css-extract-plugin
export var banner = "fenomen-module--banner--YVuon";
export var bannerC = "fenomen-module--bannerC--T780Y";
export var bannerImage = "fenomen-module--bannerImage--buE0d";
export var bgLineLeft = "fenomen-module--bgLineLeft--+bPTE";
export var bgcircle = "fenomen-module--bgcircle--fmk+3";
export var ficonImage = "fenomen-module--ficonImage--+Z1Zu";
export var layertext = "fenomen-module--layertext--G8obO";
export var mb = "fenomen-module--mb--AiE9W";
export var mt = "fenomen-module--mt--qrv-p";
export var testimonial = "fenomen-module--testimonial--BgCRq";
export var tp = "fenomen-module--tp--LICq1";
export var verticalCarouselImage = "fenomen-module--verticalCarouselImage--gK48u";
export var verticalCarouselImageContainer = "fenomen-module--verticalCarouselImageContainer--zn68X";
export var verticalCarouselRow = "fenomen-module--verticalCarouselRow--p4UA3";